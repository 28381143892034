import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import logo from "../assests/imgs/InlineLogo.png";
// import logo from "../assests/images/logo.jpg";

import search from "../assests/imgs/search-interface-symbol.png";
import menuImg from "../assests/imgs/hamburger-menu.svg";
import AppNav from "./AppNav";
import { useEffect, useState } from "react";
import NavHead from "./NavHead";
import { ImCross } from "react-icons/im";
import { FaAngleDown, FaBars } from "react-icons/fa";
import collogelogo from "../assests/images/bcit_college_logo.png"
import axios from "axios";
import { Backend_url, TOKEN } from "../Important";
function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [toggle, settoggle] = useState(true);
  const location = useLocation()
  const navigate = useNavigate();
  // let pageYoffset = 0;
  // let prevwindowoffset = 0;
  // window.addEventListener("scroll", () => {
  //   prevwindowoffset = window.pageYOffset
  //   // if (pageYoffset > 200) {
  //   //   console.log(pageYoffset)
  //   //   document.getElementById("Main-nav").style.height = "0";
  //   // }
  //   console.log(pageYoffset, prevwindowoffset)

  //   pageYoffset = window.pageYOffset;
  //   return window.removeEventListener("scroll", () => { })
  // })
  const navbar = [
    {
      name: 'Home',
      child: null,
      icon: null,
      active: '/'
    },
    {
      name: 'About',
      child: [
        {
          name: 'College Profile',
          link: null
        },
        {
          name: 'Vission & Mission',
          link: null
        },
        {
          name: 'Chairman Desk',
          link: '/desk/:chairman'
        },
        {
          name: 'Principal Desk',
          link: '/desk/:principal'
        },
        {
          name: 'Founder Principal Desk',
          link: '/desk/:founderprincipal'
        },
        {
          name: 'Recognition',
          link: null
        },
        {
          name: 'Affilation',
          link: null
        },
        {
          name: 'AISHE',
          link: null
        },
        {
          name: 'Building Plan',
          link: null
        },
        {
          name: 'Audit Report',
          link: null
        },
        {
          name: 'List of Enrollment',
          link: null
        }
      ],
      active: null,
      icon: <FaAngleDown />
    },
    {
      name: 'Administration',
      child: [
        {
          name: 'Courses',
          link: "/course"
        },
        {
          name: 'Procoture',
          link: ""
        },
        {
          name: 'Fee Structure',
          link: ""
        },
        {
          name: 'Rules & Regulation',
          link: ""
        }
      ],
      active: null,
      icon: <FaAngleDown />
    },
    {
      name: 'Faculty',
      child: [
        {
          name: 'Academic Staff',
          link: '/academicstaff'
        },
        {
          name: 'Non-Academic Staff',
          link: ''
        },
        {
          name: 'Staff Profile',
          link: '/staffprofile'
        },
        {
          name: 'Academic Calander',
          link: ''
        }
      ],
      active: null,
      icon: <FaAngleDown />
    },
    {
      name: 'Cell & Commitee',
      child: [
        {
          name: 'Covering Body'
        },
        {
          name: 'Admission Cell'
        },
        {
          name: 'College Council'
        },
        {
          name: 'Academic Calender Committee'
        },
        {
          name: 'Cultural Activity'
        },
        {
          name: 'Anti Ragging'
        },
        {
          name: 'Descipline Commitee'
        },
        {
          name: 'Placement'
        },
        {
          name: 'Alumni'
        },
        {
          name: 'Goievance & Redsessal'
        },
        {
          name: 'Parent Teacher Association'
        }
      ],
      active: null,
      icon: <FaAngleDown />
    },
    {
      name: 'Link',
      child: [
        {
          name: 'NCTE',
          link: 'https://ncte.gov.in/Website/Hindi/Index.aspx'
        },
        {
          name: 'UGC',
          link: 'https://www.ugc.gov.in/'
        },
        {
          name: 'SCERT',
          link: 'https://www.scert.cg.gov.in/'
        },
        {
          name: 'Hemchand Yadav',
          link: ''
        },
        {
          name: 'CG Board',
          link: 'https://cgbse.nic.in/'
        },
        {
          name: 'CG Scholarship',
          link: ''
        },
        {
          name: 'Our YouTube',
          link: ''
        }
      ],
      active: null,
      icon: <FaAngleDown />
    },
    {
      name: 'Student Corner',
      child: [
        {
          name: 'Activities'
        },
        {
          name: 'Study Material'
        },
        {
          name: 'Syllabus'
        },
        {
          name: 'Student Union'
        },
        {
          name: 'Student Satisfication Survey'
        }
      ],
      active: null,
      icon: <FaAngleDown />
    },


    {
      name: 'Facilities',
      child: [
        {
          name: 'Library',
        },
        {
          name: 'Coputer Lab'
        },
        {
          name: 'Play Ground'
        },
        {
          name: 'Clubs'
        }
      ],
      active: null,
      icon: <FaAngleDown />
    }
  ]
  // const [navbar, setnavbar] = useState([])

  const [scrpllposition, setsrollposition] = useState(0)
  const [inOut, setinOut] = useState([])
  const [mainmenu, setmainmenu] = useState([])
  const [submenu, setsubmenu] = useState([])
  const fetdata = async () => {
    debugger

    try {
      await fetch(`${Backend_url}/MenuMaster/getall`,
        {
          method: 'GET',
          headers: {
            'authorization': 'Bearer ' + TOKEN
          }
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const Data = data.data
          Data.map((item) => {
            if (item.GruopName == "null") {
              setmainmenu((prev) => {
                // const existing = new Set(prev)
                // const newcontact = Data.filter((Datavalue) => !existing.has(Datavalue))
                // console.log("existing", existing);
                // console.log("new", newcontact);
                // return [...existing, ...newcontact]

                const existingIds = new Set(prev.map(menuItem => menuItem.Id)); // Create a Set of existing IDs
                if (!existingIds.has(item.Id)) { // Check if the item ID already exists
                  existingIds.add(item.Id); // Add the new item's ID
                  return [...prev, item]; // Return the new array with the item added
                }
                return prev;
              });
              // setmainmenu((prev) => !prev.includes(item) ? [...prev, item] : [...prev])
            } else {
              setsubmenu((prev) => {
                const existingIds = new Set(prev.map(menuItem => menuItem.Id)); // Create a Set of existing IDs
                if (!existingIds.has(item.Id)) { // Check if the item ID already exists
                  existingIds.add(item.Id); // Add the new item's ID
                  return [...prev, item]; // Return the new array with the item added
                }
                return prev;
              });
              // setsubmenu((prev) => !prev.includes(item) ? [...prev, item] : [...prev])
            }
          })
          console.log(data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetdata()
  }, []);

  useEffect(() => {
    console.log(submenu)

  }, [submenu, mainmenu])
  const [report, setreport] = useState([])
  const getreport = async () => {
    try {
      debugger
      await fetch(`${Backend_url}/academicmaster/getAll`,
        {
          method: 'GET',
          headers: {
            'authorization': 'Bearer ' + TOKEN
          }
        }
      ).then((res) =>

        res.json()
      ).then((data) =>

        setreport(data.data)
      )


    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    getreport()
  }, [])

  const hanldlein = async (item) => {
    try {
      debugger
      inOut.includes(item.Category_sub) ? setinOut((prev) => [prev.filter((previtem) => previtem != item.Category_sub)]) : setinOut((prev) => [...prev, item.Category_sub])
      console.log(inOut);

    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    console.log('run')
    console.log(window.location.pathname)
  }, [])

  const navigatefromli = (item) => {
    if (item.URL == '/auditreport') {
      console.log('audit report')
      window.open(report.find((item) => item.EventTitle === 'audit_report').File)
      return
    }

    if (item.URL != "null") {
      navigate(item.URL)
    }
    return null
  }

  return (
    <>
      {/* <div id="Main-nav" className={`fixed top-0 left-0 w-full h-auto z-20  `}> */}
      <NavHead />
      <nav className={`${styles.nav} sm:!flex h-[80px]  sm:!top-[39px] sm:h-auto sm:px-[10px] sm:!items-center sm:!justify-start px-[40px] py-[0px]`}>

        <div className={` w-auto sm:w-full pt-[5px]  h-full sm:!justify-start  flex items-center justify-center  `}>
          <img className={` sm:w-[20px] sm:h-auto`} src={collogelogo} />
          <div className={`h-full w-auto ml-[10px] flex items-center justify-start `}>
            <h2 className={`text-xl w-[330px] font-[600] sm:w-auto sm:text-[13px] `}>Bhilai College Of
              Information Technology
            </h2>
          </div>


        </div>

        {/* ------------------------close-div------------------------ */}

        {/* ------------------navbar items---------------------------------- */}
        <div style={{ backgroundColor: '#00000059' }} onClick={() => settoggle(!toggle)} className={`w-[100%] ${toggle ? " sm:hidden " : " sm:block"} bottom-0 h-screen hidden fixed left-[0%]`}></div>

        <ul className={`w-auto ${!toggle ? " sm:flex" : " sm:hidden"} sm:gap-[5px] sm: sm:overflow-scroll sm:bg-white sm:border w-[60%] sm:items-start sm:pl-[20px] sm:h-screen sm:justify-start  h-full sm:flex-col sm:fixed sm:right-0 sm:top-[0px] flex items-center justify-center gap-[1px]`}>


          <li onClick={() => settoggle(!toggle)} className={`h-[69px] sm:flex sm:items-end hidden    w-auto relative  cursor-pointer flex-col  sm:w-full  sm:pr-[20px] text-center justify-center text-[15px]`}>
            {
              !toggle ?
                <ImCross />
                : <FaBars />
            }
          </li>

          {
            mainmenu.map((pitem) => {
              return (
                <>
                  <li onClick={() => {
                    return inOut.includes(pitem.Category_sub) ? setinOut((prev) => [prev.filter((previtem) => previtem != pitem.Category_sub)]) : setinOut((prev) => [...prev, pitem.Category_sub]);
                  }} onMouseLeave={() => hanldlein(pitem)} onMouseEnter={() => hanldlein(pitem)} className={`h-full sm:h-auto sm:items-start   w-auto relative flex cursor-pointer flex-col items-center text-center justify-center text-[15px]`}>
                    <p onClick={() => pitem.URL != "null" ? navigate(pitem.URL) : null} className={` ${inOut.includes(pitem.Category_sub) ? "bg-gray-200 rounded-md" : ""} ${location.pathname == pitem.active ? " bg-gray-200 rounded-md" : ""} font-[400] flex px-[10px] py-[5px] items-center justify-center`}>
                      {pitem.Category_sub}
                      {
                        submenu.find((item) => item.GruopName == pitem.Category_sub) ? <FaAngleDown /> : null
                      }
                    </p>
                    {
                      inOut.includes(pitem.Category_sub) ?
                        <ul className={`absolute cursor-pointer sm:static bg-white sm:bg-[#f8f9fb] px-[8px] pb-[10px] rounded-md top-[80px]`}>
                          {submenu.filter((item) => item.GruopName == pitem.Category_sub).map((item) => {
                            return (
                              <>
                                <li onClick={() => pitem.Category_sub == 'Link' && item.URL != "null" ? window.open(item.URL) : navigatefromli(item)} className={`w-[200px]   px-[10px] py-[10px] text-left rounded-md   hover:bg-gray-200`}>{item.Category_sub}</li>
                              </>
                            )
                          })}
                        </ul> : null
                    }

                  </li>
                </>
              )
            })
          }
        </ul>


        <button onClick={() => settoggle(!toggle)} className={`hidden sm:flex sm:text-[15px] text-[#4c4c4c]    p-1 rounded-md  items-center justify-center`}>
          {
            !toggle ?
              <ImCross />
              : <FaBars />
          }
        </button>
      </nav>

      {/* </div> */}
      {/* {showMenu ? <AppNav setShowMenu={setShowMenu} /> : ""} */}
    </>

  );
}

export default Navbar;



