import React, { useEffect, useRef, useState } from "react";
import styles from "./Carousel.module.css";
import Backend_url from "../Important/Backend_url";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import { useCookies } from "react-cookie";
import Token from "../Important/Token";

// ---------------slider --------------------
import img1 from "../assests/images/bcit_slider_1_.jpg";
import img2 from "../assests/images/bcit_slider_2.png";
import img3 from "../assests/images/bcit_slider_3.png";
import { TOKEN } from "../Important";


const Slideshow = () => {
  const ref = useRef()
  const slidecount = [1, 2, 3]
  const [cookies, setCookies, removeCookies] = useCookies(['token'])
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider, setslider] = useState([img1, img2, img3])
  const settings = {
    dots: true,               // Show dots for navigation
    infinite: true,           // Infinite loop
    speed: 500,               // Transition speed in milliseconds
    slidesToShow: 1,          // Number of slides to show at a time
    slidesToScroll: 1,        // Number of slides to scroll at a time
    autoplay: true,           // Enable auto-sliding
    autoplaySpeed: 1500,      // Delay between slides in milliseconds
    arrows: false,          // Hide arrows
    afterChange: (current) => setCurrentSlide(current),
  };

  // FETCH WELCOIME DETAILS
  const fetchSlider = async () => {
    debugger
    try {
      const response = await fetch(`${Backend_url}/api/sliders/all`,
        {

          headers: {

            'authorization': 'Bearer ' + TOKEN,

          }
        }
      );
      const data = await response.json();
      // console.log("object", data);
      if (response) {
        setslider(data[0].Imagepaths)
        console.log(data);
      }
    } catch (error) {
      console.error("error fetching data", error);
    }
  };
  useEffect(() => {
    fetchSlider();
  }, []);

  return (
    <div className={`mt-[130px] sm:flex sm:items-center sm:flex-col sm:mt-[70px] relative`}>

      <Slider ref={ref} className={`w-full sm:h-[130px] sm:m-0 overflow-hidden h-[480px]`} {...settings}>
        {
          slider.map((item) => {
            return (
              <>
                <div className={`w-full h-full`}>
                  <img src={item} className={`w-full h-full overflow-hidden bg-cover sm:object-fill bg-center`} />
                </div>
              </>
            )
          })
        }

      </Slider>

      <div className={`w-auto flex items-center justify-center sm:left-[41%] left-[44%] gap-[10px] top-[72%] px-[5px] h-auto absolute py-[5px]`}>
        {slidecount.map((item, index) => {
          return (
            <>
              <button onClick={() => ref.current.slickGoTo(item - 1)} className={`w-[40px] sm:p-[5px] sm:w-[15px] transition-all sm:h-[15px]  h-[40px] ${currentSlide + 1 == item ? " bg-white opacity-100 fadeanimation text-black transition-all scale-110" : ""}  flex items-center   bg-[#645d4c] opacity-50 sm:text-[8px] text-white rounded-full justify-center p-[10px]`}>
                {index + 1}
              </button>

            </>
          )
        })}
      </div>
    </div>
  );
};

export default Slideshow;
