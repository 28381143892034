import React, { useEffect, useState } from "react";
import styles from "./NoticeSlider.module.css";
import { Link } from "react-router-dom";
import Backend_url from "../Important/Backend_url";
import Token from "../Important/Token";
import { TOKEN } from "../Important";

const NoticeSlider = () => {
  // FETCH NOTICE AND NEWS
  const [notice, setnotice] = useState([])
  const fetchnotice = async function () {
    debugger
    try {
      const res = await fetch(`${Backend_url}/newsnotices/get/all`,
        {
          headers: {
            'authorization': 'Bearer ' + TOKEN
          }
        }
      );
      const data = await res.json();
      if (data) {
        console.log(data)
        setnotice(data)
      }
      // setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchnotice()
  }, [])

  useEffect(() => {
    console.log(notice)
  }, [notice])


  return (
    <div className={`${styles.container} !w-[100%] sm:!w-full`}>
      <h1 className={`text-3xl font-bold`}>
        <span>LAT</span>EST NEWS
      </h1>
      <div className={styles.noticeBoard}>
        <div className={styles.notices}>
          {notice.map((notice, index) => (
            <div key={index} className={`${styles.notice} justify-between`}>
              <div className={styles.date}>
                <h3 className={`text-red-700 text-[18px]`}>
                  {notice.Title}
                </h3>
                <p>
                  {notice.Description}
                </p>
              </div>

              <div className={``}>
                <img className={`h-[50px]`} src={notice.ImagePath} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

export default NoticeSlider;
