import styles from "./NavHead.module.css";

import instagram from "../assests/imgs/instagram.png";
import facebook from "../assests/imgs/facebook.png";
import twitter from "../assests/imgs/twitter.png";
import callIcon from "../assests/imgs/callIcon.png";

import navlogo from "../assests/images/navlogo.png";
import navlogo2 from "../assests/images/navlogo2.svg";

import logo from "../assests/imgs/logo1.png";
import { Link, useNavigate } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";

function NavHead() {
  function openGmail(gmailId) {
    // Gmail URL format for opening the inbox
    const gmailUrl = `https://mail.google.com/mail/u/bhilaicollege@rediffmail.com/`;

    // Open the Gmail inbox in a new tab or window
    window.open(gmailUrl, '_blank');
  }
  const navigate = useNavigate()
  const navigation = [
    {
      name: 'Gallery',
      url: "/gallery"
    },
    {
      name: 'IQAC',
      url: ""
    },
    {
      name: 'NAAC',
      url: ""
    }
  ]

  return (
    <div className={`${styles.container} sm:!p-0 sm:!py-[7px]`}>

      {/* <div className={`w-auto gap-[10px] h-full flex items-center justify-center flex-row`}>
         <div className={`${styles.logo} sm:!h-[50px]`}>
          <img src={`${logo} `} className={`sm:!h-[50px]`} alt="" />
           
        </div> 

        <div className={` text-md font-[600]`}>
          <h2 className={`font-[750] text-xl sm:text-[15px]`}>CHAMPION CERAMICS PVT. LTD.</h2>
          <h5 className={`sm:text-[13px]`}>(AN ISO 9001:2008 CERTIFIED COMPANY)</h5>
          <h3>WIDE RANGE OF REFRACTORIES</h3>
        </div>
      </div> */}

      {/* <div className={`${styles.navlogo} flex items-center justify-center`}>
        <img src={navlogo} alt="" />
        <img src={navlogo2} alt="" style={{ backgroundColor: "white" }} />
      </div> */}
      {/* <p className={styles.mail}>
        <span className={styles.fontW}>✉ championceramics@gmail.com</span>

        <img src={callIcon} alt="" className={styles.callIcon} />

        <span className={styles.fontW}>+91-9999999999</span>
      </p>
      <p className={styles.logo}>
        <span>
          <img src={instagram} alt="" />
        </span>
        <span>
          <img src={facebook} alt="" />
        </span>
        <span>
          <img src={twitter} alt="" />
        </span>
      </p> */}
      <div className={` px-[20px] w-full flex items-center justify-between`}>
        <div className={`w-auto h-auto flex items-center justify-center`}>
          <p className={`flex items-center justify-center gap-[4px]`}>
            <IoCall />
            0788-2283370</p>
          <div className={` h-[20px] p-[1px] mx-[15px] bg-white`} />
          <p className={`flex sm:hidden items-center justify-center gap-[4px]`}>
            <MdEmail />
            bhilaicollege@rediffmail.com</p>
        </div>

        <div className={`w-auto h-auto flex items-center justify-center`}>
          <ul className={`flex sm:hidden items-center justify-center gap-2 px-[20px]`}>
            {navigation.map((item) => {
              return (
                <>
                  <p onClick={() => navigate(item.url)} className={` font-[500] cursor-pointer flex items-center justify-center`}>
                    {item.name}
                  </p>
                </>
              )
            })}
          </ul>
          <div className={`w-auto h-auto flex items-center justify-center`}>
            <div className={`flex items-center justify-center gap-[4px]`} >
              <FaFacebook />
            </div>
            <div className={` h-[20px] p-[1px] mx-[15px] bg-white`} />
            <div onClick={openGmail} className={`flex items-center justify-center gap-[4px]`}>
              <BiLogoGmail />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavHead;
