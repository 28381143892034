import React from 'react'
import "./Banner.css"

const BAnner = ({ img, text = "Banner" }) => {
    return (
        <div style={{ height: "400px" }} className={`w-full sm:!h-[200px]   flex items-center relative justify-center`}>
            <h2 style={{ zIndex: '2' }} className={`header_text absolute sm:text-3xl gradient-text text-5xl font-bold  top-0 w-full h-full flex text-red-700 bg-gradient-to-t from-white to-black items-center justify-center`}>{text}</h2>
            <div className={`w-full h-full top-0 absolute z-[1] overflow-hidden`}>
                <img src={img} className={` w-full h-full`} />
            </div>
        </div>
    )
}

export default BAnner