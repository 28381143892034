import React from 'react'
import "./StaffProfile.css"
import Navbar from '../Navbar'
import BAnner from '../Banner/BAnner'
import Footer from '../Footer'
import Drtiwari from "../../assests/BCIT_content/academic_staff/tiwari.png"
import Anjutripathi from "../../assests/BCIT_content/academic_staff/tripathi.png"
import Bhawnasharma from "../../assests/BCIT_content/academic_staff/bhawna_sharma.png"
import Asha from "../../assests/BCIT_content/academic_staff/Asha.png"
import savitasingh from "../../assests/BCIT_content/academic_staff/Savita_Singh.png"
import Dinesh from "../../assests/BCIT_content/academic_staff/dinesh.png"
import Mousmiverma from "../../assests/BCIT_content/academic_staff/Mousmiverma.png"
import Reshma from "../../assests/BCIT_content/academic_staff/reshma.png"
import Shrijita from "../../assests/BCIT_content/academic_staff/Shrijeta.png"
import pratibha from "../../assests/BCIT_content/academic_staff/pratibha.png"
import mishra from "../../assests/BCIT_content/academic_staff/mishra.png"
import dulari from "../../assests/BCIT_content/academic_staff/dulari.png"
import pawankumar from '../../assests/BCIT_content/academic_staff/pawan kumar.png'
import Pramila from "../../assests/BCIT_content/academic_staff/Pramila.png"
import Bannerimg from '../../assests/BCIT_content/staffprofile.jpeg'
import suman from "../../assests/BCIT_content/academic_staff/suman.png"
import photo from "../../assests/BCIT_content/academic_staff/photop.png"
import ratan from "../../assests/BCIT_content/academic_staff/ratan.png"
import bedpdf from "../../assests/BCIT_content/bedstaff.pdf"
import dedpdf from "../../assests/BCIT_content/D.El.Ed._Staff_Profile.pdf"
import { Icon } from 'leaflet'
import { IoEye } from 'react-icons/io5'
import { FaDownload } from 'react-icons/fa'


const StaffProfile = () => {

    const tabeldata = [
        [
            '1',
            'Dr. Ghanshyam Tiwari',
            'Principal',
            'B.Ed 78.83%',
            '--',
            'M.A. (Philosophy) 57.50%(Hindi)56.87%',
            '01/08/2016',
            'Regular',
            // '40000',
            '06 Years',
            <img src={Drtiwari} />
        ],
        [
            '2',
            'Dr. Anju Tripathi',
            'Asst. Professor',
            'B.Ed 67.50%',
            'M.Ed 64.50%',
            'M.A. (Phychology)',
            '19/01/2013',
            'Regular',
            // '22600',
            '09 Years 09 months',
            <img src={Anjutripathi} />
            // <img src={Anjutripathi} />

        ],
        [
            '3',
            'Smt. Bhawana Kshatriya',
            'Asst. Professor',
            'B.Ed 75.25%',
            'M.Ed 64.13%',
            'M.SC.(Biotech)58.13%',
            '22/01/2013',
            'Regular',
            // '22600',
            '09 Years 09 months',
            <img src={Bhawnasharma} />
            // <img src={Anjutripathi} />

        ],
        [
            '4',
            'Smt. Asha Sharnagat',
            'Asst. Professor',
            'B.Ed 73.75%',
            'M.Ed 66.10%',
            'M.A.(HINDI)66.10%',
            '26/05/2016',
            'Regular',
            // '22600',
            '06 Years',
            <img src={Bhawnasharma} />
            // <img src={Anjutripathi} />

        ],
        [
            '5',
            'Smt. Savita Singh',
            'Asst. Professor',
            'B.Ed 75.66%',
            'M.Ed 62.37%',
            'M.A.(Phychology) 66.50%',
            '26/05/2016',
            'Regular',
            // '22600',
            '06 Years',
            <img src={savitasingh} />
            // <img src={Anjutripathi} />

        ],
        [
            '6',
            'Mr. Dinesh Kumar ',
            'Asst. Professor',
            'B.Ed 69.08%',
            'M.Ed 60.50%',
            'M.Sc.(Zoology) 66.50%',
            '26/11/2020',
            'Adhoc',
            // '22600',
            '02 Years',
            <img src={Dinesh} />
            // <img src={Anjutripathi} />

        ],
        [
            '7',
            'Smt Mousami verma',
            'Asst. Professor',
            'B.Ed 76.50%',
            'M.Ed 66.55%',
            'M.Sc.(Maths) 58.08%',
            '26/11/2020',
            'Adhoc',
            // '15600',
            '02 Years',
            <img src={Mousmiverma} />
            // <img src={Anjutripathi} />

        ],
        [
            '8',
            'Reshma Ambri',
            'Asst. Professor',
            'B.Ed 75.91%',
            'M.Ed 67%',
            'M.A.(Hindi) 59.08%',
            '26/11/2020',
            'Adhoc',
            // '15600',
            '02 Years',
            <img src={Reshma} />
            // <img src={Anjutripathi} />

        ],
        [
            '9',
            'Smt Pratibha Mishra',
            'Asst. Professor',
            'B.Ed 64.91%',
            'M.Ed',
            'M.A.(English) 57%',
            '26/11/2020',
            'Adhoc',
            // '15600',
            '02 Years',
            <img src={pratibha} />
            // <img src={Anjutripathi} />

        ],
        [
            '10',
            'Smt Pramila Patel',
            'Asst. Professor',
            'B.Ed 74.91%',
            'M.Ed 61.12%',
            'M.A.(Hindi) 58%',
            '26/11/2020',
            'Adhoc',
            // '15600',
            '02 Years',
            <img src={Pramila} />
            // <img src={Anjutripathi} />

        ],
        [
            '11',
            'Smt Shrijita Meharchandani',
            'Asst. Professor',
            'B.Ed 72.16%',
            'M.Ed 55%',
            'M.A.(Sociology) 58%',
            '26/11/2020',
            'Adhoc',
            // '15600',
            '02 Years',
            <img src={Shrijita} />
            // <img src={Anjutripathi} />

        ],
        [
            '12',
            'Dulari Sirmaura',
            'Asst. Professor',
            'B.Ed 72.16%',
            'M.Ed 56%',
            'M.A.(Sociology) 59%',
            '24/11/2020',
            'Adhoc',
            // '15600',
            '02 Years',
            <img src={dulari} />
            // <img src={Anjutripathi} />

        ],
        [
            '13',
            'Suman Singh',
            'Asst. Professor',
            'B.Ed 60%',
            'M.Ed 69%',
            'M.A.(Hindi) 56%',
            '26/02/2022',
            'Adhoc',
            // '15600',
            '04 Months',
            <img src={suman} />
            // <img src={Anjutripathi} />

        ],
        [
            '14',
            'Pawan Kumar Dewangan',
            'Asst. Professor',
            'B.F.A. 69%',
            'M.F.A. 62%',
            ' ',
            '26/05/2016',
            'Regular',
            // '15600',
            '06 Years',
            <img src={pawankumar} />
            // <img src={Anjutripathi} />

        ],
        [
            '15',
            'J. Kumar Swami',
            'Asst. Professor',
            'B.F.A. 65%',
            'M.F.A. 61%',
            'M.Sc. 48%',
            '14/10/2020',
            'Adhoc',
            // '15600',
            '02 Years',
            <img src={photo} />
            // <img src={Anjutripathi} />

        ],
        [
            '16',
            'Ratan Kumar ',
            'Asst. Professor',
            'B.P.Ed 71%',
            'M.P.Ed 63%',
            ' ',
            '30/10/2021',
            'Adhoc',
            // '15600',
            '07 Months',
            <img src={ratan} />
            // <img src={Anjutripathi} />

        ],

    ]

    const handlepopenm = (e, url) => {
        window.open(url, '_blank');
    }

    return (
        <>
            <Navbar />
            <div className={` main-academic w-full mt-[130px]   flex items-center justify-center flex-col h-auto`}>
                <BAnner img={Bannerimg} text='Academic Staff' />
                <div className={`w-full h-auto px-[30px] flex items-center justify-center flex-col`}>
                    <h1 className={`academic_head w-full flex items-center justify-center font-[550] text-[3rem] py-[30px]`}>
                        <h2 style={{ borderBottom: '3px solid red' }} className={`w-auto   h-auto flex items-center justify-center pl-[15px] pr-[15px] `}>
                            Staff Profile

                        </h2>
                    </h1>
                    <div style={{ paddingBottom: '30px' }} className={` tabel-div w-full h-auto pb-[30px]`}>
                        <table>
                            <thead>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 px-[15px] py-[10px] `}>S.No</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 px-[15px] py-[10px]`}>File Name</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 px-[15px] py-[10px]`}>Action</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={`px-[15px] py-[10px] border border-black`}>1</td>
                                    <td className={`px-[15px] py-[10px] border border-black`}>B.ED Staff Profile</td>
                                    <td className={` border border-black`}>
                                        <div className={` actionbtn  w-auto h-auto flex items-center justify-center`}>
                                            <a
                                                // onClick={(e) => handlepopenm(e, '/src/assests/BCIT_content/B.Ed._Staff_Profile.pdf')}
                                                href={bedpdf}
                                                target='_blank'
                                                className="view px-[30px] flex items-center justify-center py-[10px]"
                                            >
                                                <IoEye />
                                                View
                                            </a>
                                            <a href={bedpdf} download={true} className={`download cursor-pointer px-[30px] flex items-center justify-center py-[10px]`}>
                                                <FaDownload />
                                                Download</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={`px-[15px] py-[10px] border border-black`}>2</td>
                                    <td className={`px-[15px] py-[10px] border border-black`}>D.EI.ED Staff Profile</td>
                                    <td className={` border border-black`}>
                                        <div className={` actionbtn w-auto h-auto flex items-center justify-center`}>
                                            <a target='_blank' href={dedpdf} className={`view px-[30px] flex items-center justify-center  py-[10px]`}>
                                                <IoEye />
                                                View</a>
                                            <a href={dedpdf} className={`download px-[30px] flex cursor-pointer items-center justify-center py-[10px]`} download={true}>
                                                <FaDownload />
                                                Download</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ paddingBottom: '30px' }} className={` tabel-div w-full h-auto pb-[30px]`}>
                        <table>
                            <thead>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>S.No</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Name</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Designation</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>B.Ed%</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>M.Ed%</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>PG Subject%</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Date of Appointment in Statue-28</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Status (Regular/Adhoc/Contract)</th>
                                {/* <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Scale of Pay</th> */}
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Experience</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Attached Photo</th>
                            </thead>
                            <tbody>
                                {
                                    tabeldata.map((row, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    {
                                                        row.map((cell, index) => {
                                                            return (
                                                                <>
                                                                    <td style={{ paddingLeft: '15px', paddingRight: '15px' }} className={`border border-black pl-[20px]`}>
                                                                        {cell}
                                                                    </td>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default StaffProfile