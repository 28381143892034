import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./pages/HomePage";
import InfraStructurePage from "./pages/InfraStructurePage";
import RefactorApplicationPage from "./pages/RefactorApplicationPage";
import ProductPage from "./pages/ProductPage";
import OurTeamPage from "./pages/OurTeamPage";
import OurClientPage from "./pages/OurClientPage";
import ContactusPage from "./pages/ContactusPage";
import AboutusPage from "./pages/AboutusPage";
import Profile from "./components/Profile";
import QualityMeasures from "./components/QualityMeasures";
import CompanyLocationMap from "./components/CompanyLocationMap";

import ProductLayout from "./pages/ProductLayout";
import ProductItem from "./components/ProductItem";
import OurTeamLayout from "./components/OurTeamLayout";
import PageNotFound from "./components/PageNotFound";
import Gallery from "./components/Gallery";
import IronSteelPlant from "./components/IronSteelPlant";
import PowerPlant from "./components/PowerPlant";
import CopperPlant from "./pages/CopperPlant";
import CementPlant from "./components/CementPlant";
import GlassPlant from "./components/GlassPlant";
import Aluminium from "./components/Aluminium";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import Course from "./components/Course/Course";
import DeskPage from "./components/DeskPage/DeskPage";
import BCIT from "./context/contexprovider";
import Store from "./context/contexstore";
import GAllery from "./components/Gallery/GAllery";
import AcademicStaff from "./components/Academic staff/AcademicStaff";
import StaffProfile from "./components/StaffProfile/StaffProfile";
import Library from "./components/Library/Library";
import Enrollment from "./components/Enrollment/Enrollment";

function App() {


  return (
    <div>
      <BrowserRouter>
        <Store>

          <Routes>

            <Route index element={<HomePage />} />
            <Route path="/course" element={<Course />} />
            <Route path="/desk/:id" element={<DeskPage />} />
            {/* <Route path="*" element={<PageNotFound />} /> */}
            <Route path="/gallery" element={<GAllery />} />
            <Route path="/academicstaff" element={<AcademicStaff />} />
            <Route path="/staffprofile" element={<StaffProfile />} />
            <Route path="/library" element={<Library />} />
            <Route path="/enrollment" element={<Enrollment />} />
          </Routes>
        </Store>
      </BrowserRouter>
    </div>
  );
}

export default App;
