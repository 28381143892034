import React, { useEffect, useState } from 'react'
import { Backend_url, TOKEN } from '../../Important'
import Navbar from '../Navbar'
import Footer from '../Footer'
import "./Gallery.css"
import BAnner from '../Banner/BAnner'
import bannerimg from "../../assests/BCIT_content/gallery/galleryback2.jpeg"
import Bigimg from '../BigGalleryimg/Bigimg'

const GAllery = () => {
    const [galleryimage, setgalleryimage] = useState([])
    const [Opengallery, setOpenTogle] = useState(false)
    const [gallerysrc, setsrcgallery] = useState(null)
    const opengallery = (src) => {
        setOpenTogle(!Opengallery)
        setsrcgallery(src.Imagepath)
    }

    // --------------fetchgalleryimg--------------------------------
    const fetchgalleryimg = async () => {
        debugger
        try {
            const response = await fetch(`${Backend_url}/gallery/images/all`,
                {
                    headers: {
                        'Authorization': `Bearer ${TOKEN}`
                    }
                }
            );
            const data = await response.json();
            console.log(data)
            console.log(data)
            setgalleryimage(data?.groupedImages?.GalleryImage)
            // setHomeimage(data?.groupedImages.HomepageCollege);
        } catch (error) {
            console.error("Error fetching products", error);
        }
    }
    useEffect(() => {
        fetchgalleryimg()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Navbar />
            <div className={`allcontent-gallery w-full mt-[130px] min-h-[500px] max-h-max flex items-center justify-center flex-col gap-1 py-[30px] pt-[40px] pb-[30px]`}>
                <BAnner text='Photo Gallery' img={bannerimg} />
                {/* <h1 className={`w-full h1_gallery h-auto text-[7rem] font-[650] py-[15px] flex items-center justify-center`}>
                    Gallery
                </h1> */}

                <div className={`w-full h-auto flex flex-wrap items-center justify-center`}>
                    {galleryimage.slice(0, 8).map((item) => {
                        return (
                            <div onClick={() => opengallery(item)} className={`w-[300px] h-[280px] border border-[#ccc] overflow-hidden cursor-pointer`}>
                                <img className={`w-full h-full`} src={item.Imagepath} alt={item.Name} />
                            </div>
                        )
                    })}
                </div>
            </div>

            {
                Opengallery && <Bigimg close={setOpenTogle} imgurl={gallerysrc} />
            }
            <Footer />
        </>

    )
}

export default GAllery