import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import BAnner from '../Banner/BAnner'
import "./Library.css"
import galleryback from "../../assests/BCIT_content/3016.jpg"
import { TOKEN } from '../../Important'


const Library = () => {
    const [librarytext, setlibrarytext] = useState(null)
    const [rules, setrules] = useState(null)
    const [tabeldata, settabledata] = useState(null)
    const library = "The College has well maintained Library to facilitate the academic pursuit of the students and the staff. The Library subscribes to the standard Journals Magazine and News Paper.The library has more than 6000 latest books. Every Year new books with latest edition and authors are being accessioned.The library regularly subscribes 10 national journals. Various periodicals, general books and leading newspapers in Hindi & English are made available. Reference section has quite a good number of Encyclopedias, Educational surveys of research, books on general knowledge and fictions."
    const genralruler = [
        "Every student can get two books from the library at a time for 07 days.",
        "If the books not returned after this interval then they should be charged late fees at the cost of Rs 5/- per book per day for first three days and later on Rs 10/- per day",
        "The student should check carefully the books at the time of exodus and if any pages of the book is cut or missing than they should inform at once to the librarian and to be signed by him/her.",
        "To write, underline, removing pages or convey any loss to the book is punishable.",
        "The suggestion is given to the students that they should know by their selves all the rules and information of the library.",
        "Library rules shall be strictly followed. Any student, who takes books from the college library without permission or cut pages out of library books, exposes himself / herself to the gravest penalties. Mutilation or loss of library books shall be compensated by payment of thrice of the amount of the book price. Sub-lending of books is strictly forbidden. "
    ]
    const tabledata = [
        "8108",
        "10",
        "3"
    ]


    const fetchcontact = async () => {
        debugger
        try {
            const response = await fetch(`https://tc2.yes-bm.com/api/jsonroute/get`, {
                method: 'GET', headers: {
                    'authorization': 'Bearer ' + TOKEN
                }
            })
            const data = await response.json()
            data.json.forEach((item) => {
                try {
                    const parsedObject = JSON.parse(item.JsonObject);
                    // Use the 'name' property (or any unique identifier) as the key
                    if (parsedObject.name === 'library_text') {
                        setlibrarytext(parsedObject)

                    }
                    if (parsedObject.name === 'library_rules') {
                        setrules(parsedObject)
                    }
                    if (parsedObject.name === 'tabledata') {
                        settabledata(parsedObject)
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            });

            console.log(data)
        } catch (error) {
            console.error('Error:', error)
        }
    }
    useEffect(() => {
        fetchcontact()
    }, [])


    return (
        <>
            <Navbar />
            <div className={` main-academic w-full mt-[130px]   flex items-center justify-center flex-col h-auto`}>
                <BAnner img={galleryback} text='Library' />
                <div className={` w-full  px-[30px] py-[15px] h-auto`}>
                    {/* ------------library---------------------- */}
                    <div className={`w-auto h-auto flex items-center justify-center flex-col`}>
                        <h2 className={`h2_rules w-full h-auto flex items-center justify-start `}>Library</h2>
                        <div className={`w-full h-auto p-[15px]`}>
                            <p className={`px-[20px] py-[15px]`}>{library}</p>
                        </div>
                    </div>
                    <div className={`w-auto h-auto flex items-center justify-center flex-col`}>
                        <h2 className={`h2_rules w-full h-auto flex items-center justify-start `}>The General Rules of Library</h2>
                        <ul>
                            {
                                genralruler?.map((item, index) => {
                                    return (
                                        <>
                                            <li className={` li-rules w-full flex items-center justify-start`}>
                                                <span className={`w-[90px]`}>
                                                    {index + 1}
                                                </span>
                                                <p>
                                                    {item}
                                                </p>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    {/* -----------book details--------------- */}
                    <div className='w-auto h-auto flex py-[30px] items-start justify-center flex-col'>
                        <table>
                            <thead>
                                <th className={`px-[15px] bg-black  text-white py-[4px]`}>
                                    Number of books
                                </th>
                                <th className={`px-[15px] py-[4px] text-white bg-black`}>
                                    Number of Journals
                                </th>
                                <th className={`px-[15px] py-[4px] text-white bg-black`}>
                                    News papers
                                </th>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        tabledata.map((item, index) => {
                                            return (
                                                <>
                                                    <td style={{ border: '1px solid gray', paddingLeft: '15px' }} className={`border border-gray-500 pl-[25px]`}>{item}</td>
                                                </>
                                            )
                                        })
                                    }
                                    {/* <td style={{ border: '1px solid gray', paddingLeft: '15px' }} className={`border border-gray-500 pl-[25px]`}>10</td> */}
                                    {/* <td style={{ border: '1px solid gray', paddingLeft: '15px' }} className={`border border-gray-500 pl-[25px]`}>3</td> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Library