import React from 'react'
// import "./AcademicStaff.css"
import Drtiwari from "../../assests/BCIT_content/academic_staff/tiwari.png"
import Anjutripathi from "../../assests/BCIT_content/academic_staff/tripathi.png"
import Bhawnasharma from "../../assests/BCIT_content/academic_staff/bhawna_sharma.png"
import Asha from "../../assests/BCIT_content/academic_staff/Asha.png"
import savitasingh from "../../assests/BCIT_content/academic_staff/Savita_Singh.png"
import Dinesh from "../../assests/BCIT_content/academic_staff/dinesh.png"
import Mousmiverma from "../../assests/BCIT_content/academic_staff/Mousmiverma.png"
import Reshma from "../../assests/BCIT_content/academic_staff/reshma.png"
import Shrijita from "../../assests/BCIT_content/academic_staff/Shrijeta.png"
import pratibha from "../../assests/BCIT_content/academic_staff/pratibha.png"
import mishra from "../../assests/BCIT_content/academic_staff/mishra.png"
import dulari from "../../assests/BCIT_content/academic_staff/dulari.png"
import pawankumar from '../../assests/BCIT_content/academic_staff/pawan kumar.png'
import BAnner from '../Banner/BAnner'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Bannerimg from '../../assests/BCIT_content/Academicstaff.jpeg'
import "./Academicstaff.css"

const AcademicStaff = () => {
    const tabeldata = [
        [
            '1',
            'Dr. Ghanshyam Tiwari',
            'M.Ed., M.A. (Philosophy), Ph.D',
            'Principal/Asst. Professor',
            'Education',
            '08/01/2016',
            'Sec-28',
            <img src={Drtiwari} />
        ],
        [
            '2',
            'Dr. Anju Tripathi',
            'M.Ed., M.Com.,M.A (Psychology), M.A.(Economics) Ph.D',
            'Asst. Professor',
            'Education',
            '19/01/2013',
            'Sec-28',
            <img src={Anjutripathi} />

        ],
        [
            '3',
            'Ms. Bhawana Sharma',
            'M.Ed., M.Sc. (Biotechnology)',
            'Asst. Professor',
            'Education',
            '22/01/2013',
            'Sec-28',
            <img src={Bhawnasharma} />

        ],
        [
            '4',
            'Ms. Asha Sharnagat',
            'M.Ed., M.A. (Hindi)',
            'Asst. Professor',
            'Education',
            '26/05/2016',
            'Sec-28',
            <img src={Asha} />

        ],
        [
            '5',
            'Ms. Savita Singh',
            'M.Ed., M.A. (Psychology)',
            'Asst. Professor',
            'Education',
            '26/05/2016',
            'Sec-28',
            <img src={savitasingh} />

        ],
        [
            '6',
            'Mr. Dinesh Kumar',
            'M.Ed., M.Sc. (Zoology)',
            'Asst. Professor',
            'Education',
            '26/11/2020',
            'Adhoc By Managment',
            <img src={Dinesh} />

        ],
        [
            '7',
            'Ms. Mousami Verma',
            'M.Ed., M.Sc. (Maths)',
            'Asst. Professor',
            'Education',
            '26/11/2020',
            'Adhoc By Managment',
            <img src={Mousmiverma} />

        ],
        [
            '8',
            'Ms. Reshma Ambri',
            'M.Ed., M.A. (Hindi)',
            'Asst. Professor',
            'Education',
            '26/11/2020',
            'Adhoc By Managment',
            <img src={Reshma} />

        ],
        [
            '9',
            'Ms. Shrijeta Meherchandani',
            'M.Ed., M.Com.',
            'Asst. Professor',
            'Education',
            '24/11/2020',
            'Adhoc By Managment',

            <img src={Shrijita} />
        ],
        [
            '10',
            'Ms. Pramila Patel',
            'M.Ed., M.A. (Hindi)',
            'Asst. Professor',
            'Education',
            '26/11/2020',
            'Adhoc By Managment',
            <img src={pratibha} />

        ],
        [
            '11',
            'Ms. Pratibha Mishara',
            'M.Ed., M.A. (English)',
            'Asst. Professor',
            'Education',
            '24/11/2020',
            'Adhoc By Managment',
            <img src={mishra} />

        ],
        [
            '12',
            'Ms. Dulari Sirmaur',
            'M.Ed., M.A. (Sociology)',
            'Asst. Professor',
            'Education',
            '24/11/2020',
            'Adhoc By Managment',
            <img src={dulari} />

        ],
        [
            '13',
            'Mr. Pawann Kumar Dewangan',
            'B.F.A ,M.F.A',
            'Asst. Prof.(Visual Arts)',
            'Education',
            '27/12/2018',
            'Adhoc By Managment',
            <img src={pawankumar} />

        ]
    ]
    return (
        <>
            <Navbar />
            <div className={` main-academic w-full mt-[130px]   flex items-center justify-center flex-col h-auto`}>
                <BAnner img={Bannerimg} text='Academic Staff' />
                <div className={`w-full h-auto px-[30px] flex items-center justify-center flex-col`}>
                    <h1 className={`academic_head w-full flex items-center justify-center font-[550] text-[3rem] py-[30px]`}>
                        <h2 style={{ borderBottom: '3px solid red' }} className={`w-auto   h-auto flex items-center justify-center pl-[15px] pr-[15px] `}>
                            Academic Staff

                        </h2>
                    </h1>
                    <div style={{ paddingBottom: '30px' }} className={` tabel-div w-full h-auto pb-[30px]`}>
                        <table>
                            <thead>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>S.No</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Name</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Qualification</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Designation</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Department</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Date of appointment</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Status (Regular/Adhoc/Contractual)</th>
                                <th style={{ border: '1px solid black' }} className={`bg-gray-300 `}>Attached Photograph</th>
                            </thead>
                            <tbody>
                                {
                                    tabeldata.map((row, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    {
                                                        row.map((cell, index) => {
                                                            return (
                                                                <>
                                                                    <td style={{ paddingLeft: '15px', paddingRight: '15px' }} className={`border border-black pl-[20px]`}>
                                                                        {cell}
                                                                    </td>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}

export default AcademicStaff
