import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import BAnner from '../Banner/BAnner'
import bannerback from "../../assests/BCIT_content/bcitgallery4.jpeg"
import { useCookies } from 'react-cookie'
import { Backend_url, TOKEN } from '../../Important'


const Enrollment = () => {
    const [report, setreport] = useState([])
    const [cookies, setcookies, removecookies] = useCookies(['token'])

    const getreport = async () => {
        try {
            debugger
            await fetch(`${Backend_url}/academicmaster/getAll`,
                {
                    method: 'GET',
                    headers: {
                        'authorization': 'Bearer ' + TOKEN
                    }
                }
            ).then((res) =>

                res.json()
            ).then((data) => {
                console.log(data)
                setreport(data.data)
            }
            )


        } catch (error) {
            console.log(error)
        }
    }


    const hanldeopen = (id) => {

        if (id == 'b.ed') {
            console.log('audit report')
            window.open(report.find((item) => item.EventTitle === 'B.Ed').File)
            return
        }
        if (id === 'D.El.Ed') {
            window.open(report.find((item) => item.EventTitle === 'D.El.Ed').File)
            return
        }

        // if (item.URL == '/enrollment') {
        //   window.open(report.find((item) => item.EventTitle === 'enrollment').File)
        //   return
        // } 
    }

    useEffect(() => {
        getreport()
    }, [])


    return (
        <>
            <Navbar />
            <div className={` main-academic w-full mt-[130px]   flex items-center justify-center flex-col h-auto`}>
                <BAnner img={bannerback} text='Enrollment' />
                <div className={` w-full  px-[30px] py-[15px] h-auto`}>
                    <div className={`w-auto h-auto flex items-center justify-center flex-col`}>
                        <h2 className={`h2_rules w-full h-auto flex items-center justify-start `}>Courses Enrollment</h2>
                        <ul className={`w-full py-[30px]`}>
                            <li className={`hover:text-red-400 font-[500] cursor-pointer`}>
                                <a className='py-[4px] pr-[20px]'>1. B.Ed. Enrollment</a>

                                <button onClick={() => hanldeopen('b.ed')} className={`text-red-500`}>
                                    View
                                </button>
                            </li>
                            <li className={`hover:text-red-400 font-[500] cursor-pointer`}>
                                <a className='py-[4px] pr-[20px]'>2. D.El.Ed Enrollment</a>
                                <button onClick={() => hanldeopen('D.El.Ed')} className={`text-red-500`}>
                                    View
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Enrollment