import { Link, useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";
import logo from "../assests/images/bcit_college_logo.png"
import "./Footer.module.css"
import callIcon from "../assests/imgs/call.png";
import address from "../assests/imgs/global-network.png";
import { useEffect, useState } from "react";
import Backend_url from "../Important/Backend_url";
import Token from "../Important/Token";
import { FaFacebook, FaHeadphonesAlt, FaLocationArrow, FaMapPin } from "react-icons/fa";
import { BsCalendar2Date, BsTwitterX } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { TOKEN } from "../Important";
import { GoDotFill } from "react-icons/go";

function Footer() {
  const [Contact, setContact] = useState({
    phoneNo: "dsfsdf"
  })
  const [ulforcourse, setulforcourse] = useState([])
  const [Quicklink, setquicklink] = useState({})
  const [aboutcollege, setaboutcollege] = useState({})
  const [RecentUpdate, setRecentUpdate] = useState({})
  const [contactdetails, setcontactdetails] = useState(null)
  const [Email, setEmail] = useState(null)
  const [followus, setfollowus] = useState(null)
  const navigate = useNavigate()

  // FETCH LOGO
  const fetchlogo = async () => {
    try {
      const response = await fetch(`${Backend_url}/Event/images/all`,
        {
          headers: {
            'authorization': 'Bearer ' + Token
          }
        }
      ).then((res) => res.json()).then((data) => {
        console.log(Object.values(data.groupedImages)[3][0])
        // setlogo(Object.values(data.groupedImages)[3][0])
      })
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  }


  // FETCH Contact
  const [jsonObjects, setJsonObjects] = useState({});
  const fetchcontact = async () => {
    debugger
    try {
      const response = await fetch(`${Backend_url}/jsonroute/get`, {
        method: 'GET', headers: {
          'authorization': 'Bearer ' + TOKEN
        }
      })
      const data = await response.json()
      data.json.forEach((item) => {
        try {
          const parsedObject = JSON.parse(item.JsonObject);
          // Use the 'name' property (or any unique identifier) as the key
          if (parsedObject.name === 'contact') {
            setContact({
              [parsedObject.name]: parsedObject
            })
          }
          if (parsedObject.name === 'RecentUpdate') {
            setRecentUpdate(parsedObject)
          }
          if (parsedObject.name === 'quicklink') {
            setquicklink(parsedObject)
          }
          if (parsedObject.name === 'aboutcollege') {
            setaboutcollege(parsedObject)
          }
          if (parsedObject.name === 'followUs') {
            setfollowus(followus)
          }
          if (parsedObject.name === 'fcourses') {
            setulforcourse(parsedObject.data)
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      });

      console.log(data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchcontact()
  }, [])

  // useEffect(() => {
  //   console.log(jsonObjects)

  //   setContact(jsonObjects.contact)
  // }, [jsonObjects])

  useEffect(() => {
    console.log(Quicklink)

  }, [Quicklink])



  // ------social media-------------------------
  const icon = [
    {
      name: 'facebook',
      link: followus?.facebook,
      icon: <FaFacebook />
    },
    {
      name: "Twitter",
      link: followus?.x,
      icon: <BsTwitterX />
    },
    {
      name: "Gmail",
      link: followus?.gmail,
      icon: <BiLogoGmail />
    }
  ]

  useEffect(() => {

    fetchlogo()
  }, [])

  function getcontact(filterkey) {
    const contact = Object.keys(jsonObjects).find((item) =>
      item === "contact"
    )

    const object = jsonObjects[contact]
    // setContact(object)
    return object
  }
  // console.log(Phone)
  const contact = getcontact() || {}


  // console.log(Object.keys(data))
  console.log(contact.name)
  console.log(getcontact())
  console.log(Object.keys(jsonObjects))
  return (
    <>
      <footer className={`w-full sm:flex-col text-[#77899d] gap-[25px] min-h-[150px] flex items-start justify-start max-h-max bg-[#002147] px-[30px] py-[30px] !pt-[90px]`}>
        {/* first */}
        <div className={`w-[23%] sm:w-full gap-2 flex items-center justify-center flex-col`}>
          {/* logo */}
          <div className={`footerlogo w-full gap-[4px] h-auto flex items-center justify-start `}>
            <img src={aboutcollege.logo} />
            <h2 className={`h-full w-auto text-[20px] text-orange-300 font-[550]`}>
              {aboutcollege.collegename}
            </h2>
          </div>

          {/* para */}
          <div className={` w-full sm:text-[15px] h-auto mb-[10px] text-[#c6d1d1]`}>

            <p>{aboutcollege.para}</p>
          </div>

          {/* div */}
          <div className={`w-full text-[#c6d1d1] h-auto flex items-start justify-start flex-col`}>
            <h3 className={`text-[20px] w-full flex items-center justify-start sm:text-[17px] font-[700]`}>Follow Us</h3>
            <ul className={`flex items-start justify-start gap-2 py-[10px] `}>
              {
                icon.map((item) => {
                  return (
                    <>
                      <span onClick={() => window.open(item.link, '_blank')} className={`w-auto text-[15px] h-auto`}>
                        {item.icon}
                      </span>
                    </>
                  )
                })
              }
            </ul>
          </div>
        </div>

        {/* quick links */}
        <div className={`w-[23%] sm:w-full flex items-start justify-start px-[20px] flex-col `}>
          <h2 className={`w-full flex items-center justify-start  py-[31px] !pt-0 text-orange-300 sm:text-[20px] sm:py-[10px] text-[27px] font-[550]`}>Quick Links</h2>
          <div className={`w-full flex text-[#c6d1d1] items-center justify-start flex-col `}>
            <p className={`w-full flex sm:text-[14px] items-center justify-between`}>
              <p onClick={() => window.open(Quicklink?.NCTE, '_blank')}>NCTE</p>
              <p onClick={() => window.open(Quicklink?.UGC, '_blank')}>UGC</p>
            </p>

            <p className={`w-full sm:text-[14px] sm:font-[400]  flex items-center justify-between`}>
              <p onClick={() => window.open(Quicklink?.SCRET, '_blank')}>SCRET</p>
              <p onClick={() => window.open(Quicklink?.UNIVERSITY, '_blank')}>UNIVERSITY</p>
            </p>

            <p className={`w-full sm:text-[14px] sm:font-[400]  flex items-center justify-between`}>
              <p onClick={() => window.open(Quicklink?.Infrastructure, '_blank')}>Infrastructure</p>
              <p onClick={() => window.open(Quicklink?.Milestone, '_blank')}>Milestone</p>
            </p>

            <p className={`w-full sm:text-[14px] sm:font-[400]  flex items-center justify-between`}>
              <p onClick={() => window.open(Quicklink?.AboutUs, '_blank')}>About Us</p>
              <p onClick={() => window.open(Quicklink?.ContactUs, '_blank')}>Contact Us</p>
            </p>
          </div>
        </div>

        {/* recent Updates*/}
        <div className={`w-[23%] sm:w-full flex items-start justify-start px-[20px] flex-col`}>
          <h2 className={`w-full flex items-center justify-start  py-[31px] !pt-0 text-orange-300 sm:text-[20px] sm:py-[10px] text-[27px] font-[550]`}>Courses</h2>
          <div className={`flex w-full items-start justify-start `}>
            <ul className={`w-full`}>
              {
                ulforcourse.map((item) => {
                  return (
                    <>
                      <li onClick={() => navigate('/course')} className={` flex cursor-pointer items-center justify-between w-full gap-1`}>

                        <p className={`text-[15px] flex items-center justify-start font-[600] text-[#c6d1d1]`}>
                          <span className={`w-auto h-auto flex items-center justify-center text-[#c6d1d1] text-[15px] font-[500]`}>
                            <GoDotFill />
                          </span>
                          {item}
                        </p>
                        <span className={`w-auto h-auto text-[13px]`}>
                          <FaLocationArrow />
                        </span>
                      </li>
                    </>
                  )
                })
              }
            </ul>

          </div>
        </div>

        {/* Contact Us */}
        <div className={`w-[23%] sm:w-full flex items-start justify-start px-[20px] flex-col`}>
          <h2 className={`w-full flex items-center justify-start  py-[31px] !pt-0 text-orange-300 sm:text-[20px] sm:py-[10px] text-[27px] font-[550]`} >
            Contact Us
          </h2>
          <div className={`w-full flex items-start justify-center flex-col`}>
            <p className={`flex gap-1 items-center justify-center`}>
              <span className={`flex text-yellow-400 p-[10px] sm:p-[4px] w-auto items-center justify-center`}>
                <FaHeadphonesAlt />
              </span>
              <p className={`text-[#c6d1d1] sm:text-[13px]`}>{Contact?.contact?.phoneNo}</p>
            </p>

            <p className={`flex gap-1 items-center justify-start`}>
              <span className={`flex text-yellow-400 p-[10px] sm:p-[4px] w-auto items-center justify-center`}>
                <MdEmail />
              </span>
              <p className={` sm:text-[13px] text-[#c6d1d1]`}>{Contact?.contact?.email}</p>
            </p>

            <p className={`flex gap-1 items-center justify-start`}>
              <span className={`flex text-yellow-400 p-[10px] sm:p-[4px] w-auto items-center justify-center`}>
                <FaMapPin />

              </span>
              <p className={` sm:text-[13px] text-[#c6d1d1]`}>{Contact?.contact?.address}</p>
            </p>
          </div>
        </div>

        {/* ---------------copyright------------------ */}

      </footer >

      <div className={`w-full  flex items-center justify-center bg-[#3e6086] text-white py-[7px] font-[600] text-[15px]`}>
        <p>Developed & Maintained By Techcherry</p>
      </div>
    </>
  );
}

export default Footer;




// <div className={styles.footer}>
// <div className={`${styles.footerContent} !justify-center`}>
//   {/* <div className={`${styles.flexC} w-auto`}> */}
//   <div className={`${styles.logo} px-[10px]  `}>
//     <Link to="/">
//       <img src={logo} alt="logo" />
//     </Link>
//   </div>
//   {/* <p className={styles.center}></p> */}
//   {/* </div> */}
//   <div className={styles.flexC}>
//     <h3>Contact Info.</h3>
//     <p className={`${styles.space} flex   items-center justify-start gap-[5px]`}>
//       <img src={address} alt="" className={styles.callIcon} />
//       <b>Address:</b>
//       <p >
//         Ward no. 4, Durga Mandir Ward ,Nandini Road Jamul, Bhilai(C.G.), PIN 490024
//       </p>
//     </p>


//     <p className={`${styles.space} flex items-center justify-start gap-[5px]`}>
//       <img src={callIcon} alt="" className={styles.callIcon} />{" "}
//       <b>Phone:</b>
//       <p>0788-2283370</p>
//     </p>

//     {/* <span className={styles.fontW}> 91-7819-245693/245761</span>
//     <span className={styles.fontW}> 91-7819-245761</span> */}
//     {/* <div dangerouslySetInnerHTML={{ __html: Phone?.TextContent }} /> */}

//     <p className={`${styles.space} flex items-center justify-start gap-[5px]`}>
//       ✉ <b>Email: </b>
//       <p>bhilaicollege@rediffmail.com</p>
//     </p>

//     {/* <div dangerouslySetInnerHTML={{ __html: Email?.TextContent }} /> */}
//     <div className={styles.googleMap}>
//       <a
//         href="https://maps.app.goo.gl/DGuX5RSKjoKHV7yW6"
//         className={`${styles.location} flex items-center justify-start gap-[5px]`}
//         target="_blank"
//         rel="noreferrer"
//       >
//         <span>
//           <img src={map} alt="map" />
//         </span>
//         <span className={styles.googleMapIcon}>Google Map</span>
//       </a>
//     </div>
//   </div>
//   <div className={styles.flexC}>
//     <h3>Quick Links</h3>
//     <div className={styles.flexRow}>
//       <div className={styles.flex}>
//         <Link to="/" className={styles.quickLink}>
//           <span className={styles.fontW}>Administration</span>
//         </Link>
//         <Link to="/aboutus" className={styles.quickLink}>
//           <span className={styles.fontW}></span>
//         </Link>
//         <Link to="/infrastructure" className={styles.quickLink}>
//           <span className={styles.fontW}>Infrastructure</span>
//         </Link>
//         <Link to="/refactorapplication" className={styles.quickLink}>
//           <span className={styles.fontW}>Refactor Application</span>
//         </Link>
//       </div>
//       <div className={styles.flex}>
//         <Link to="/product" className={styles.quickLink}>
//           <span className={styles.fontW}>Product</span>
//         </Link>
//         <Link to="/ourteam" className={styles.quickLink}>
//           <span className={styles.fontW}>Our Team</span>
//         </Link>
//         <Link to="/ourclients" className={styles.quickLink}>
//           <span className={styles.fontW}>Our Client</span>
//         </Link>
//         <Link to="/contactus" className={styles.quickLink}>
//           <span className={styles.fontW}>Contact Us</span>
//         </Link>
//       </div>
//     </div>
//     <div className={`${styles.space1} flex items-center justify-center`}>
//       <Link className={`${styles.logoLink} hover:scale-125 transition-all hover:transition-all`}>
//         <img src={instagram} alt="instagram" />
//       </Link>
//       <Link className={`${styles.logoLink}  hover:scale-125 transition-all hover:transition-all `}>
//         <img src={facebook} alt="facebook" />
//       </Link>
//       <Link className={`${styles.logoLink}  hover:scale-125 transition-all hover:transition-all `}>
//         <img src={twitter} alt="twitter" />
//       </Link>
//       <div></div>
//     </div>
//   </div>
// </div>
// <div className={`${styles.copyright} !p-[10px]`}>
//   <span>
//     © Copyright 2024 Champion Ceramics. All rights reserved. Design By
//     TechCherry
//   </span>
// </div>
// </div>
