import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { FaArrowsToDot } from 'react-icons/fa6'
import BAnner from '../Banner/BAnner'
import CoursesBack from "../../assests/BCIT_content/CourseBack.jpg"
import "./Courses.css"
import { TOKEN } from '../../Important'

const Course = () => {
    const course = [
        {
            name: "Bachelor of Science (BSc)",
            content: "Bachelor of Science (BSc) courses are undergraduate programs that focus on scientific and technical subjects. These programs typically span three to four years, depending on the country and specific university requirements.Generally, BSc courses last 3 to 4 years, depending on the country and the university's curriculum structure.",
            tabel: [
                "Bachelor of Science (BSc)",
                "Yearly",
                "3",
                "40",
                "10+2(Math's Background)",
                ""
            ]
        },
        {
            name: "Bachelor of Commerce (B.Com)",
            content: "Bachelor of Commerce (B.Com) is an undergraduate degree that typically spans three years. It is designed to provide students with a strong foundation in business principles, accounting, finance, and economics.",
            tabel: [
                "Bachelor of Commerce (B.Com)",
                "Yearly",
                "3",
                "100",
                "10+2(Commerce/Science subject)",
                ""
            ]
        },
        {
            name: "Bachelor of Education (B.Ed)",
            content: "A Bachelor of Education (B.Ed) is a professional degree that prepares students to become educators. It typically focuses on both theoretical knowledge and practical teaching skills.Generally, candidates must have completed a bachelor's degree (BA, B.Sc, B.Com, etc.) with a minimum percentage, often around 50% to 55%.",
            tabel: [
                "Bachelor of Education (B.Ed)",
                "Semester",
                "2",
                "100",
                "Graduation in any Stream",
                "29970"
            ]
        },
        {
            name: "Bachelor of Computer Applications (BCA)",
            content: "Bachelor of Computer Applications (BCA) is an undergraduate degree program that focuses on computer science, software development, and applications",
            tabel: [
                "Bachelor of Computer Applications (BCA)",
                "Yearly",
                "3",
                "40",
                "10+2(Any Stream)",
                ""
            ]
        },
        {
            name: "Diploma in Elementary Education (D.El.Ed)",
            content: "The Diploma in Elementary Education (D.El.Ed) is a professional training program designed to prepare teachers for the elementary education sector (classes I to VIII).",
            tabel: [
                "Diploma in Elementary Education (D.El.Ed)",
                "Yearly",
                "2",
                "50",
                "10+2(Any Stream)",
                "19000"
            ]
        }
    ]

    const [jsonObjects, setJsonObjects] = useState([]);
    const fetchdata = async function () {
        try {
            const response = await fetch(`https://tc2.yes-bm.com/api/jsonroute/get`, {
                method: 'GET', headers: {
                    'authorization': 'Bearer ' + TOKEN
                }
            })
            const data = await response.json()

            // setJsonObjects(data)
            data.json.forEach((item) => {
                try {
                    const parsedObject = JSON.parse(item.JsonObject);
                    // Use the 'name' property (or any unique identifier) as the key
                    if (parsedObject.name === 'courses') {
                        setJsonObjects(parsedObject.data)
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            });

            console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(jsonObjects)
    }, [jsonObjects]);

    useEffect(() => {
        fetchdata()
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <div className={`w-full min-h-[300px] mt-[130px] sm:!mt-[70px] sm:!min-h-0 max-h-max flex items-center justify-center flex-col`}>
                <BAnner text='Courses' img={CoursesBack} />
                <div className={`w-full h-auto flex items-center justify-center py-[20px]`}>
                    <h1 className={`course-heading font-[600] text-3xl sticky top-0`}>Courses we provide</h1>

                </div>

                <div className={`w-full px-[30px] py-[15px] sm:px-[0px] h-auto flex items-start justify-center flex-col`}>
                    {
                        course?.map((item) => {
                            return (
                                <>
                                    <div className={` insidecourses flex items-start sm:!px-[0px] py-[20px] px-[25px] flex-col justify-center `}>
                                        <div className={` w-full h-auto flex items-start py-[20px] justify-center flex-col`}>
                                            <h2 className={` text-xl font-[500] font-serif border-l-[4px]  border-l-red-700 px-[10px] py-[10px] my-[20px] `}>{item.name}</h2>
                                            <p className={`text-[15px] sm:h-auto font-[400] gap-[5px] text-gray-500 flex items-start justify-start`}>
                                                <span className={`w-auto flex items-center justify-center h-auto mt-[5px]`}>
                                                    <FaArrowsToDot />
                                                </span>
                                                <p className={`course-content`}>{item.content}</p>
                                            </p>
                                        </div>
                                        {/* tabel */}
                                        <div className={`tabel-div sm:w-full w-full h-auto sm:overflow-scroll`}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className={`w-[200px]  h-auto px-[10px] py-[10px] border  border-gray-400`}>S.No</th>
                                                        <th className={`w-[200px] h-auto px-[10px] py-[10px] border border-gray-400`}>Courses</th>
                                                        <th className={`w-[200px] h-auto px-[10px] py-[10px] border border-gray-400`}>Yearly/Sem.</th>
                                                        <th className={`w-[200px] h-auto px-[10px] py-[10px] border border-gray-400`}>Duration</th>
                                                        <th className={`w-[200px] h-auto px-[10px] py-[10px] border border-gray-400`}>No. Of Seats</th>
                                                        <th className={`w-[200px] h-auto px-[10px] py-[10px] border border-gray-400`}>Eligibility</th>
                                                        <th className={`w-[200px] h-auto px-[10px] py-[10px] border border-gray-400`}>Fee</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        {
                                                            <td className={`w-[200px] h-auto px-[10px] py-[10px] border border-gray-400`}>1</td>
                                                        }
                                                        {item?.tabel?.map((item) => {
                                                            return (
                                                                <>
                                                                    <td className={`w-[200px] px-[10px] h-auto py-[10px] border border-gray-400`}>
                                                                        {item}
                                                                    </td>
                                                                </>
                                                            )
                                                        })}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Course