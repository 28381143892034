import React, { useContext, useEffect, useState } from 'react'
import "./DeskPage.css"
import Navbar from '../Navbar'
import Footer from '../Footer'
import testimg from "../../assests/BCIT_content/bcitgallery1.jpg"
import BCIT from '../../context/contexprovider'
import { useLocation } from 'react-router-dom'
import principal from "../../assests/BCIT_content/Principal.png"
import founder from "../../assests/BCIT_content/founderprincipal.png"
import { Backend_url, TOKEN } from '../../Important'
import axios from 'axios'


const DeskPage = ({ text = "No text", src = "", name = "", position = "" }) => {
    const { desk, setdesk } = useContext(BCIT)
    const location = useLocation()
    const Desk = [
        {
            name: "From Chairmain's Desk",
            image: testimg,
            personname: "",
            fulltext: "Take Up an idea, devote yourself to it Work on in patience, the sun will rise for you. Swami Vivekananda With the above philosophy of Swami Vivekananda, I came to the conclusion that success definitely comes, if task is taken with concrete idea, sincere devotion and patience. Consequently, I discussed with our board directors for establishing a college in Bhilai. With the cooperation of all our board direcors a college came into existence in the name and style of Bhilai college of Information Technology.I,on behalf of all the board of directors take pride in declaring that in the last years, the college has achieved a very good results with some outstanding individual performances. Welcome to Bhilai College of Information Technology for Higher Education and prospective career there on. Our dream is to make this College A COLLEGE OF EXCELLENCE. Beside all our devotions and patience for this concrete idea;our Sun will only rise when you say. YES this is the COLLEGE OF EXCELLENCE",
        },
        {
            name: "From Principal's Desk",
            image: principal,
            personname: "Dr. G.S Tiwari",
            fulltext: "Bhilai College of Information Technology, run by Maharishi Patanjali Shikshan Samiti, is situated in a grand campus in Jamul, Bhilai. Our aim is to create students with high quality education along with social and moral values. For the past many years, students who have passed out from B.Ed., B.Com., B.Sc. (Computer Science) and B.C.A. faculties are working in many institutions. High quality teachers provide education in the institution. The college is running in its own new campus in Jamul from the session 2004-42. From the session 2007-88, we have received recognition for D.El.Ed. course from the National Council for Teacher Education and from this session, we are starting PGDCA and DCA courses. Affiliation with the University is expected. There is a plan to start new courses in the college from the new academic session as per the demands of the industrial sector. We promise that we will make every possible effort to maintain excellence. ",
        },
        {
            name: "Founder Principal Desk",
            image: founder,
            personname: "Prof. B.N.Dubey",
            fulltext: "Bhilai College of Information Technology, run by Maharishi Patanjali Shikshan Samiti, is situated in a grand campus in Jamul, Bhilai. In front of the college, the life-size statue of the creator of modern India, the first Prime Minister of the nation, Late Pandit Jawaharlal Nehru, is adorning the college and inspiring us that just as Nehru ji was dedicated to science and technical education, in the same way this institution situated at the feet of his statue will make his dreams come true. High class education with excellent quality will be our objective. High class academic scholars who are meritorious and whose articles have been published in national and international journals will carry out our teaching work. We promise that we will not participate in lowering the standards of excellence or imparting substandard education. I send my best wishes in advance to the college to be run in its own grand campus with the same objectives in future.",
        }
    ]

    const [HompageDesk, setHomepageDesk] = useState([])
    const fetchdesk = async (e) => {
        debugger
        try {
            await axios.get(`${Backend_url}/HPBodyCard/getall`, {
                headers: {
                    'authorization': 'Bearer ' + TOKEN
                }
            }).then((res) => {
                console.log(res.data)
                setHomepageDesk(res.data.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchdesk()
    }, [])

    useEffect(() => {
        console.log(HompageDesk);
        console.log(location.pathname.split(":")[1]);
        if (location.pathname.split(":")[1] == "principal" && HompageDesk) {

            setdesk({
                text: HompageDesk[1]?.Details,
                src: HompageDesk[1]?.CardImage,
                name: null,
                Name: HompageDesk[1]?.URL,
                position: HompageDesk[1]?.Title
            })
        }

        if (location.pathname.split(":")[1] == "chairman" && HompageDesk) {
            setdesk({
                text: HompageDesk[0]?.Details,
                src: HompageDesk[0]?.CardImage,
                name: null,
                Name: HompageDesk[0]?.URL,
                position: HompageDesk[0]?.Title
            })
        }

        if (location.pathname.split(":")[1] == "founderprincipal") {
            setdesk({
                text: HompageDesk[2]?.Details,
                src: HompageDesk[2]?.CardImage,
                name: null,
                Name: HompageDesk[2]?.URL,
                position: HompageDesk[2]?.Title
            })
        }

    }, [location.pathname, HompageDesk]);

    useEffect(() => {
        console.log(desk)
    }, [desk])


    return (
        <>
            <Navbar />
            <div className={` deskmain   w-full mt-[130px] flex items-center justify-center flex-col min-h-[200px] !px-[50px] pl-[50px] `}>
                {/* top */}
                <div className={`w-full  py-[30px] h-auto flex items-start justify-center flex-col`}>
                    <h2 className={`position-name w-full text-[30px] border-l-[3px] border-l-red-700 px-[10px] font-[600]`}>{desk.position}
                        <p className={`text-[15px]`}>{desk.name}</p>
                    </h2>

                </div>
                <div className={`w-full img-content  h-auto flex items-center justify-center `}>
                    {/* img */}
                    <div className={`img-div flex items-center justify-center !w-[45%] h-auto`}>
                        <img src={desk.src} className={`w-full h-full`} />
                    </div>
                    {/* para */}
                    <div className={` w-[45%]`}>
                        <p>{desk.text}</p>

                        <div style={{ paddingRight: '50px' }} className={`w-full text-[18px] font-[500] py-[50px] h-[200px] flex items-center justify-end`}>
                            <h2>{`-${desk.Name}`}</h2>
                        </div>
                    </div>

                    {/* --------------bottom name------------------ */}

                </div>
            </div >
            <Footer />
        </>
    )
}

export default DeskPage