import { Link, useNavigate } from "react-router-dom";
import founderPrincipal from "../assests/images/founder_principalDesk_Image.jpg"
import styles from "../pages/HomePage.module.css";
import book from "../assests/images/download.jpeg"
import Navbar from "../components/Navbar";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import EnquiryForm from "../components/EnquiryForm";
import NoticeSlider from "../components/NoticeSlider";
import objectiveimg from "../assests/images/objective.jpg"
import Principalmsg from "../assests/images/principal-message.jpg"
import LatestEvent from "../assests/images/LatestEvent.jpeg"
import Galleryimg from "../assests/images/PhotoGallery.jpg"
import Backend_url from "../Important/Backend_url";
import axios from "axios";
import Token from "../Important/Token";
import { useCookies } from "react-cookie";
import { useContext, useEffect, useState } from "react";
import BcaCourses from "../assests/BCIT_content/BCACOURSES.jpg"
import B_ed from "../assests/images/B.ed.jpg"
import Bcom from "../assests/images/bCOM.jpeg"
import bsc from "../assests/images/bsc.jpeg"
// -------------latest event-------------
import le1 from "../assests/images/LatestEvent1.jpeg"
import le2 from "../assests/images/LatestEvent2.jpeg"
import le3 from "../assests/images/LatestEvent3.jpeg"
import le4 from "../assests/images/LatestEvent4.jpeg"
import BCIT from "../context/contexprovider";
import { TOKEN } from "../Important";
import Bigimg from "../components/BigGalleryimg/Bigimg";

function HomePage() {
  const navigate = useNavigate()
  const [content, setcontent] = useState([])
  const { desk, setdesk } = useContext(BCIT)
  const [industries, setindustries] = useState([
    {
      Name: 'Our Objective',
      ImageUrl: objectiveimg
    },
    {
      Name: 'Principal Message',
      ImageUrl: Principalmsg
    },
    {
      Name: 'Latest News',
      ImageUrl: LatestEvent
    },
    {
      Name: 'Photo Gallery',
      ImageUrl: Galleryimg
    }

  ])
  const [homepage, sethomepageabout] = useState({})
  const [Products, setProducts] = useState({
    'MEHANDI': {
      image: le1
    },
    'DANCE': {
      image: le2
    },
    'ANNUAL FUNCTION': {
      image: le4
    },
    'RANGOLI': {
      image: le3
    },
  })
  const [re_render, setre_render] = useState(false)

  // Fetch all details
  const Fetchdata = async () => {
    try {
      const response = await fetch(`${Backend_url}/HpContentMaster/getall`, {
        method: 'GET', headers: {
          'authorization': 'Bearer ' + Token
        }
      })
      const data = await response.json()
      setcontent(data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  // FETCH INDUSTRIES
  const Industries = async () => {
    debugger
    try {
      const Alltestimonials = await fetch(`${Backend_url}/Testimonial/getall`,
        {
          headers: {
            'authorization': 'Bearer ' + Token
          },
        }
      ).then((res) => res.json())
      // setindustries(Alltestimonials.data)
    } catch (error) {
      console.log('Error:', error)
    }
  }

  console.log(industries)
  console.log(content)

  const [HomePageImage, setHomeimage] = useState({})
  // CALL OUR HOMEPAGE IMAGE
  const fetchProducts = async () => {
    debugger
    try {
      const response = await fetch(`${Backend_url}/gallery/images/all`,
        {
          headers: {
            'Authorization': `Bearer ${TOKEN}`
          }
        }
      );
      const data = await response.json();
      console.log(data)
      console.log(data)
      setHomeimage(data?.groupedImages.HomepageCollege);
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  useEffect(() => {
    console.log(HomePageImage)
  }, [HomePageImage])
  const [fullpara, setfullpara] = useState(false);
  function trimpara(para = "", words = 100, id = null) {
    if (para.length > words && fullpara == false) {
      const trim = para.slice(0, words);
      return (
        <button
          className={`text-left sm:overflow-scroll flex items-center justify-start flex-wrap`}
          onClick={() => setfullpara(!fullpara)}
        >
          {`${trim}`}
          <p className={`text-[black] font-[Poppins] text-[15px]`}>...more</p>
        </button>
      );
    } else {
      return (
        <button
          className={`text-left`}
          onClick={() => setfullpara(!fullpara)}
        >{`${para}`}</button>
      );
    }
  }

  // ----------------------set home page cart------------------------------------
  const [HompageDesk, setHomepageDesk] = useState([])
  const fetchdesk = async (e) => {
    debugger
    try {
      await axios.get(`${Backend_url}/HPBodyCard/getall`, {
        headers: {
          'authorization': 'Bearer ' + TOKEN
        }
      }).then((res) => {
        console.log(res.data)
        setHomepageDesk(res.data.data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchdesk()
  }, [])

  useEffect(() => {
    console.log(HompageDesk)


  }, [HompageDesk])

  // -----------------desk---------------------------
  const Desk = [
    {
      name: HompageDesk.find((item) => item.Heading == "chairman desk")?.Title,
      image: HompageDesk.find((item) => item.Heading == "chairman desk")?.CardImage,
      personname: "",
      fulltext: "Take Up an idea, devote yourself to it Work on in patience, the sun will rise for you. Swami Vivekananda With the above philosophy of Swami Vivekananda, I came to the conclusion that success definitely comes, if task is taken with concrete idea, sincere devotion and patience. Consequently, I discussed with our board directors for establishing a college in Bhilai. With the cooperation of all our board direcors a college came into existence in the name and style of Bhilai college of Information Technology.I,on behalf of all the board of directors take pride in declaring that in the last years, the college has achieved a very good results with some outstanding individual performances. Welcome to Bhilai College of Information Technology for Higher Education and prospective career there on. Our dream is to make this College A COLLEGE OF EXCELLENCE. Beside all our devotions and patience for this concrete idea;our Sun will only rise when you say. YES this is the COLLEGE OF EXCELLENCE",
      description: trimpara(HompageDesk.find((item) => item.Heading == "chairman desk")?.Details, 300, "chairman")
    },
    {
      name: HompageDesk.find((item) => item.Heading == "principal desk")?.Title,
      image: HompageDesk.find((item) => item.Heading == "principal desk")?.CardImage,
      personname: "Dr. G.S Tiwari",
      fulltext: "Bhilai College of Information Technology, run by Maharishi Patanjali Shikshan Samiti, is situated in a grand campus in Jamul, Bhilai. Our aim is to create students with high quality education along with social and moral values. For the past many years, students who have passed out from B.Ed., B.Com., B.Sc. (Computer Science) and B.C.A. faculties are working in many institutions. High quality teachers provide education in the institution. The college is running in its own new campus in Jamul from the session 2004-42. From the session 2007-88, we have received recognition for D.El.Ed. course from the National Council for Teacher Education and from this session, we are starting PGDCA and DCA courses. Affiliation with the University is expected. There is a plan to start new courses in the college from the new academic session as per the demands of the industrial sector. We promise that we will make every possible effort to maintain excellence. ",
      description: trimpara(HompageDesk.find((item) => item.Heading == "principal desk")?.Details, 300, "principal")
    },
    {
      name: HompageDesk.find((item) => item.Heading == "Founder principal desk")?.Title,
      image: HompageDesk.find((item) => item.Heading == "Founder principal desk")?.CardImage,
      personname: "Prof. B.N.Dubey",
      fulltext: "Bhilai College of Information Technology, run by Maharishi Patanjali Shikshan Samiti, is situated in a grand campus in Jamul, Bhilai. In front of the college, the life-size statue of the creator of modern India, the first Prime Minister of the nation, Late Pandit Jawaharlal Nehru, is adorning the college and inspiring us that just as Nehru ji was dedicated to science and technical education, in the same way this institution situated at the feet of his statue will make his dreams come true. High class education with excellent quality will be our objective. High class academic scholars who are meritorious and whose articles have been published in national and international journals will carry out our teaching work. We promise that we will not participate in lowering the standards of excellence or imparting substandard education. I send my best wishes in advance to the college to be run in its own grand campus with the same objectives in future.",
      description: trimpara(HompageDesk.find((item) => item.Heading == "Founder principal desk")?.Details, 300, "founderp")
    }
  ]

  const Courses = [
    {
      name: "Bsc",
      image: bsc,
      link: "/course"
    },
    {
      name: "Bcom",
      image: Bcom,
      link: "/course"
    },
    {
      name: "B.ED",
      image: B_ed,
      link: "/course"
    },
    {
      name: "BCA",
      image: BcaCourses,
      link: "/course"
    },
    {
      name: "D.EI.Ed",
      image: book,
      link: "/course"
    }
  ]

  const [galleryimage, setgalleryimage] = useState([])
  // --------------fetchgalleryimg--------------------------------
  const fetchgalleryimg = async () => {
    debugger
    try {
      const response = await fetch(`${Backend_url}/gallery/images/all`,
        {
          headers: {
            'Authorization': `Bearer ${TOKEN}`
          }
        }
      );
      const data = await response.json();
      console.log(data)
      console.log(data)
      setgalleryimage(data?.groupedImages?.GalleryImage)
      // setHomeimage(data?.groupedImages.HomepageCollege);
    } catch (error) {
      console.error("Error fetching products", error);
    }
  }


  // ----------------------fetch about-----------------------------
  const fetcchaboutcollege = async () => {
    debugger
    try {
      const response = await fetch(`${Backend_url}/jsonroute/get`, {
        method: 'GET', headers: {
          'authorization': 'Bearer ' + TOKEN
        }
      })
      const data = await response.json()
      data.json.forEach((item) => {
        try {
          const parsedObject = JSON.parse(item.JsonObject);
          // Use the 'name' property (or any unique identifier) as the key
          if (parsedObject.name === 'homepageabout') {
            sethomepageabout(parsedObject)
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      });

      console.log(data)
    } catch (error) {
      console.error('Error:', error)
    }
  }


  useEffect(() => {
    console.log(homepage)
  }, [homepage])

  useEffect(() => {
    fetcchaboutcollege()
  }, [])



  useEffect(() => {
    fetchgalleryimg()
  }, [])


  // ---------------------number and text-----------------------------
  const NumberWithText = [
    {
      text: "More than 2,000 students have benefited from the diverse range of courses offered at Bhilai College of Information Technology (BCIT).",
      number: "2,000+"
    },
    {
      text: "Approximately 70–90% of graduates from Bhilai College of Information Technology (BCIT) successfully secure job placements each year through the college's dedicated placement drives and recruitment programs.",
      number: "80%"
    },
    {
      text: "30+ qualified teachers with over years of experience are serving in our institution, and BCIT provides resource for teachers seeking to enhance their skills and effectiveness in the classroom.",
      number: "30+"
    }
  ]
  const [Opengallery, setOpenTogle] = useState(false)
  const [gallerysrc, setsrcgallery] = useState(null)
  const opengallery = (src) => {
    setOpenTogle(!Opengallery)
    setsrcgallery(src)
  }

  useEffect(() => {
    Fetchdata()
    Industries()
    fetchProducts()
  }, [])

  return (
    <>

      <Navbar />
      <Carousel />

      <div className={styles.home}>
        <div className={`w-full sm:flex-col h-auto flex px-[20px] !gap-[30px] py-[15px] items-center justify-center `}>
          {/* <h2 className={`h-auto w-full flex items-center justify-center py-[15px] font-[500] text-xl`}>About Bhilai College of Information Technology</h2>
  <p className={`w-full px-[40px]`}>The college has fabulous building science its inception. The college had adequate and well furnished classroom., seminar hall, lecture rooms, science lab computer lab and ET lab , work experience lab, Multi Subject lab, Psychology Lab etc. with latest equipments and instruments. The college has its own tube well , Garden and purified water with aqua flow are supplied to College and Hostel. Separate Boys and girls common room are equipped with indoor games , Cafeteria, mess for hostellers are provided as and when required</p> */}
          {/* welcome */}
          <div className={`w-[28%] sm:w-[100%] h-auto `}>
            <h2 className="text-2xl my-[15px] font-[500]">{homepage.showname}</h2>
            <p>{homepage?.aboutcollege}</p>
          </div>

          {/* image */}
          <div className={`w-[27%] sm:w-full flex items-center justify-start h-auto object-cover bg-center bg-cover`}>
            <img className={`w-full bg-center object-cover h-[300px]`} src={HomePageImage[0]?.Imagepath} />
          </div>
          {/* latest */}
          <div className={`w-[27%] h-auto sm:w-full `}>
            <NoticeSlider />
          </div>
        </div>


        {/* --------------desk------------------------- */}
        <div className={`w-full gap-[15px] sm:flex-col flex items-start justify-center`}>
          {
            Desk.map((item) => {
              return (
                <>
                  <div className={`w-[28%] sm:w-full h-auto flex items-center gap-[10px] justify-center flex-col px-[10px] py-[15px] `}>
                    <h3 className={`w-full flex items-center justify-start text-3xl font-[600]`}>{item.name}</h3>
                    <div className={`deskimg w-full flex items-center justify-start `}>
                      <img className={`w-auto h-[98px] sm:h-auto bg-center`} src={item.image} />
                    </div>
                    <p className={`w-full leading-6 text-gray-400`}>{item.description}</p>
                  </div>

                </>
              )
            })
          }



        </div>




        <div className={`${styles.infraStructureContent} sm:!pt-[30px]`}>
          <h2 style={{ textAlign: "center" }}>
            <span className={`font-bold text-xl px-[10px] py-[4px]`}>COURSES WE OFFER</span>
          </h2>
          {/* <p>We have widest Product Basket which includes following items :-</p> */}
          <div className={styles.productContent}>
            <div className={`${styles.infraImg} sm:flex-wrap py-[20px]`}>
              {
                Courses.map((key) => {
                  return (
                    <>
                      <div onClick={() => navigate(key.link)} className={`${styles.box3} group !relative rounded-[40px]`}>
                        {/* <Fadeimg> */}
                        <img src={key.image} className={`border border-[#ccc]`} alt="ProductImg" />
                        {/* </Fadeimg> */}
                        <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18] font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0      `}>
                          <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                          <h3 className={`text-animation z-20 text-[30px] cursor-pointer text-white`}>
                            {key.name}
                          </h3>
                        </div>
                      </div>
                    </>
                  )
                })
              }


            </div>

            {/* ----------gallery------------------- */}
            <div className={`w-full h-auto flex items-center justify-center flex-col`}>
              <div className={`w-full items-center justify-center flex-col `}>
                <h2 className={`w-full text-center text-2xl font-[500] py-[8px] !m-0 `}>OUR GALLERY</h2>
                <div className={`w-full flex items-center justify-center gap-[10px]`}>
                  <div className={`w-[30px] h-[2px] bg-gray-300`} />
                  <p className={`w-auto h-auto text-[17px]`}>View All</p>
                  <div className={`w-[30px] h-[2px] bg-gray-300`} />
                </div>

                <div className={`w-full h-auto flex items-center justify-center`}>
                  <p className={`w-[37%] py-[10px] text-center flex items-center justify-center`}>View Some images of Our events,tours,function,activities Internal and external.</p>
                </div>
              </div>

              {/* --------images----------- */}
              <div className={`w-[100%] gallery px-[20px] h-auto  flex items-center justify-center   flex-wrap`}>
                {galleryimage.slice(0, 8).map((item) => {
                  return (
                    <div onClick={() => opengallery(item.Imagepath)} className={`w-[300px] h-[280px] border border-[#ccc] overflow-hidden cursor-pointer`}>
                      <img className={`w-full h-full`} src={item.Imagepath} alt={item.Name} />
                    </div>
                  )
                })}
                <div className={`h-auto w-full pr-[4px] py-[3px] flex items-center justify-end`}>
                  <p onClick={() => navigate('/gallery')} className={`font-[550] cursor-pointer`}>See more</p>
                </div>
              </div>


              {/* -------------------statics------------------------- */}
              <div className={`w-full !py-[80px] gap-[20px]  h-auto flex items-center justify-center flex-col`}>
                <div className={` w-full flex items-center justify-center flex-col`}>
                  <h2 className={`w-full !py-[10px] !m-0 text-2xl font-[650] flex items-center justify-center !text-gray-500`}>OUR STATISTICS</h2>
                  <h3 className={`flex items-center text-2xl font-[400] justify-center w-full`}>SOME INTERESTING STATS</h3>
                </div>

                <div className={` w-full sm:flex-col flex items-start justify-center gap-[25px]`}>
                  {
                    NumberWithText.map((item) => {
                      return (
                        <>
                          <div className={` flex w-[30%] sm:w-full items-center justify-center flex-col`}>
                            <h2 className={`text-6xl font-[650] flex py-[40px] sm:!text-[30px] !m-0 items-center font-serif justify-center !text-[#7c007c]`}>{item.number}</h2>
                            <p className={`w-[100%] font-serif text-[20px] font-[300] text-center sm:text-[17px]`}>{item.text}</p>
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      {
        Opengallery && <Bigimg close={setOpenTogle} imgurl={gallerysrc} />
      }
      {/* <div className={styles.enquiryNotice}>

        <EnquiryForm />
      </div>
      <GallerySlider /> */}
      <Footer />
    </>
  );
}

export default HomePage;



