import React from 'react'
import "./Bigimg.css"

const Bigimg = ({ imgurl, close }) => {

    if (!close) {
        return null
    }

    return (
        <div style={{ zIndex: '40' }} className={`big_img_main w-[100%]  h-screen fixed top-0 left-0 right-0 flex items-center justify-center`}>
            <img src={imgurl} style={{ width: '600px', height: 'auto' }} className={`!w-[500px] !h-[250px] bg-center object-cover`} />
            <div onClick={() => close()} className={` blackgradient top-0 left-0 w-screen h-screen bg-black opacity-[0.5]`} />
        </div>
    )
}

export default Bigimg