import { useState } from "react";
import BCIT from "./contexprovider";


const Store = ({ children }) => {
    const [desk, setdesk] = useState(
        {
            text: null,
            src: null,
            name: null,
            position: null
        }
    )

    return (
        <BCIT.Provider value={{ desk, setdesk }}>
            {children}
        </BCIT.Provider>
    )
}


export default Store;